import { PortableText, PortableTextComponents } from '@portabletext/react';
import React from 'react';
import * as styles from './Lyrics.module.scss';

type Props = {
    lyrics: any;
}

const Lyrics = ({ lyrics }) => {
    return (
        <div className={styles.lyrics}>
            <PortableText value={lyrics} components={components} />
        </div>
    )
}

const components: PortableTextComponents = {
    block: {
        intro: ({ children }) => <div className={styles.intro}>{children}</div>,
        verse: ({ children }) => <div className={styles.verse}>{children}</div>,
        chorus: ({ children }) => <div className={styles.chorus}>{children}</div>,
    },
}

export default Lyrics