import { graphql, Link } from 'gatsby';
import React, { Fragment } from 'react';
import {Helmet} from 'react-helmet';
import Layout from '../components/Layout';
import PageContent from '../components/PageContent';
import ReleaseList from '../components/ReleaseList';
import Lyrics from '../components/Lyrics';
import * as styles from './SongTemplate.module.scss';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getName } from '../components/Distributor';

const SongTemplate = (props) => {
	const { track, releases } = props.data;

	return (
		<Layout>
            <Helmet htmlAttributes={{lang: 'en'}}>
                <title>{track.title} by Ballistix</title>
            </Helmet>
            <PageContent>
                <div className={styles.wrapper}>
                    <div className={styles.primary}>
                        <h1>{track.title}</h1>
                        {track.instrumental ? (
                            <p>Instrumental</p>
                        ) : (
                            <Lyrics lyrics={track._rawLyrics} />
                        )}
                    </div>
                    
                    <div className={styles.secondary}>
                        <h2>This song appears on:</h2>
                        <ul className={styles.releases}>
                            {releases?.edges?.map(({ node: release }) => (
                                <li key={release.title}>
                                    <Link to={`/releases/${release.slug?.current}/`}><GatsbyImage image={release.coverArt?.asset?.gatsbyImage} alt={`Cover art for ${release.title} by Ballistix`} /></Link>
                                    {release.streamingLinks && release.streamingLinks.length > 0 && (
                                        <Fragment>
                                            <h3 className={styles.distribution}>Listen on:</h3>
                                            <ul className={styles.distributors}>
                                                {release.streamingLinks?.map(({ url, distributor }) => (
                                                    <li key={distributor}><a href={url} className={styles.distributor}>{getName(distributor)}</a></li>
                                                ))}
                                            </ul>
                                        </Fragment>
                                    )}

                                    {release.downloadLinks && release.downloadLinks.length > 0 && (
                                        <Fragment>
                                            <h3 className={styles.distribution}>Purchase from:</h3>
                                            <ul className={styles.distributors}>
                                                {release.downloadLinks?.map(({ url, distributor }) => (
                                                    <li key={distributor}><a href={url} className={styles.distributor}>{getName(distributor)}</a></li>
                                                ))}
                                            </ul>
                                        </Fragment>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </PageContent>
            <ReleaseList />
		</Layout>
	);
};

export default SongTemplate;

export const pageQuery = graphql`
	query($id: String!) {
		track: sanityTrack(_id: { eq: $id }) {
			title
			instrumental
            _rawLyrics(resolveReferences: {maxDepth: 10})
		}
		releases: allSanityRelease(
            filter: { tracks: { elemMatch: { _id: { eq: $id } } } }
            sort: {fields: releaseDate, order: DESC}
        ) {
			edges {
				node {
                    coverArt {
                        asset {
                            gatsbyImage(width: 260, height: 260, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                        }
                    }
					slug {
						current
					}
                    title
					streamingLinks {
						distributor
						url
					}
					downloadLinks {
						distributor
						url
					}
				}
			}
		}
	}
`;
